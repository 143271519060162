@import "frappe/public/css/fonts/inter/inter.css";
@import "/assets/bookclinic/figma/css/bootstrap.min.css";
@import "/assets/bookclinic/figma/css/owl.carousel.css";
@import "/assets/bookclinic/figma/css/owl.theme.default.css";
@import "/assets/bookclinic/figma/css/select2.min.css";
@import "/assets/bookclinic/figma/css/magnific-popup.css";
@import "/assets/bookclinic/figma/css/lightboxed.css";
@import "/assets/bookclinic/figma/css/datepicker.css";
@import "/assets/bookclinic/figma/css/animate.min.css";
@import "/assets/bookclinic/figma/css/style.css";
@import "/assets/bookclinic/figma/css/responsive.css";
@import "/assets/bookclinic/figma/css/pnotify.css";
:root {
  --neutral-white: #ffffff;
  --neutral-black: #000000;
  --neutral: var(--neutral-white);
  --invert-neutral: var(--neutral-black);
  --gray-50: #f8f8f8;
  --gray-100: #f3f3f3;
  --gray-200: #ededed;
  --gray-300: #e2e2e2;
  --gray-400: #c7c7c7;
  --gray-500: #999999;
  --gray-600: #7c7c7c;
  --gray-700: #525252;
  --gray-800: #383838;
  --gray-900: #171717;
  --blue-50: #f7fbfd;
  --blue-100: #edf6fd;
  --blue-200: #e3f1fd;
  --blue-300: #c9e7fc;
  --blue-400: #70b6f0;
  --blue-500: #0289f7;
  --blue-600: #007be0;
  --blue-700: #0070cc;
  --blue-800: #005ca3;
  --blue-900: #004880;
  --green-50: #f3fcf5;
  --green-100: #e4f5e9;
  --green-200: #daf0e1;
  --green-300: #cae5d4;
  --green-400: #b6dec5;
  --green-500: #59ba8b;
  --green-600: #30a66d;
  --green-700: #278f5e;
  --green-800: #16794c;
  --green-900: #173b2c;
  --red-50: #fff7f7;
  --red-100: #fff0f0;
  --red-200: #fcd7d7;
  --red-300: #f9c6c6;
  --red-400: #eb9091;
  --red-500: #e03636;
  --red-600: #cc2929;
  --red-700: #b52a2a;
  --red-800: #941f1f;
  --red-900: #6b1515;
  --orange-50: #fff9f5;
  --orange-100: #fff1e7;
  --orange-200: #fce6d5;
  --orange-300: #f7d6bd;
  --orange-400: #f0b58b;
  --orange-500: #e86c13;
  --orange-600: #d45a08;
  --orange-700: #bd3e0c;
  --orange-800: #9e3513;
  --orange-900: #6b2711;
  --amber-50: #fdfaed;
  --amber-100: #fcf3cf;
  --amber-200: #f7e28d;
  --amber-300: #f5d261;
  --amber-400: #f2be3a;
  --amber-500: #e79913;
  --amber-600: #db7706;
  --amber-700: #b35309;
  --amber-800: #91400d;
  --amber-900: #763813;
  --yellow-50: #fffcef;
  --yellow-100: #fff7d3;
  --yellow-200: #f7e9a8;
  --yellow-300: #f5e171;
  --yellow-400: #f2d14b;
  --yellow-500: #edba13;
  --yellow-600: #d1930d;
  --yellow-700: #ab6e05;
  --yellow-800: #8c5600;
  --yellow-900: #733f12;
  --cyan-50: #f5fbfc;
  --cyan-100: #e0f8ff;
  --cyan-200: #b3ecfc;
  --cyan-300: #94e6ff;
  --cyan-400: #6bd3f2;
  --cyan-500: #34bae3;
  --cyan-600: #32a4c7;
  --cyan-700: #267a94;
  --cyan-800: #125c73;
  --cyan-900: #164759;
  --teal-50: #f0fdfa;
  --teal-100: #e6f7f4;
  --teal-200: #bae8e1;
  --teal-300: #97ded4;
  --teal-400: #73d1c4;
  --teal-500: #36baad;
  --teal-600: #0b9e92;
  --teal-700: #0f736b;
  --teal-800: #115c57;
  --teal-900: #114541;
  --violet-50: #fbfaff;
  --violet-100: #f5f2ff;
  --violet-200: #e5e1fa;
  --violet-300: #dad2f7;
  --violet-400: #bdb1f0;
  --violet-500: #6846e3;
  --violet-600: #5f46c7;
  --violet-700: #4f3da1;
  --violet-800: #392980;
  --violet-900: #251959;
  --pink-50: #fff7fc;
  --pink-100: #feeef8;
  --pink-200: #f8e2f0;
  --pink-300: #f2d4e6;
  --pink-400: #e9c4da;
  --pink-500: #e34aa6;
  --pink-600: #cf3a96;
  --pink-700: #9c2671;
  --pink-800: #801458;
  --pink-900: #570f3e;
  --purple-50: #fdfaff;
  --purple-100: #f9f0ff;
  --purple-200: #f1e5fa;
  --purple-300: #e9d6f5;
  --purple-400: #d6c1e6;
  --purple-500: #9c45e3;
  --purple-600: #8642c2;
  --purple-700: #6e399d;
  --purple-800: #5c2f83;
  --purple-900: #401863;
  --white-overlay-50: rgba(255,255,255,0.090);
  --white-overlay-100: rgba(255,255,255,0.180);
  --white-overlay-200: rgba(255,255,255,0.270);
  --white-overlay-300: rgba(255,255,255,0.360);
  --white-overlay-400: rgba(255,255,255,0.450);
  --white-overlay-500: rgba(255,255,255,0.540);
  --white-overlay-600: rgba(255,255,255,0.630);
  --white-overlay-700: rgba(255,255,255,0.720);
  --white-overlay-800: rgba(255,255,255,0.810);
  --white-overlay-900: rgba(255,255,255,0.900);
  --black-overlay-50: rgba(0,0,0,0.090);
  --black-overlay-100: rgba(0,0,0,0.180);
  --black-overlay-200: rgba(0,0,0,0.270);
  --black-overlay-300: rgba(0,0,0,0.360);
  --black-overlay-400: rgba(0,0,0,0.450);
  --black-overlay-500: rgba(0,0,0,0.540);
  --black-overlay-600: rgba(0,0,0,0.630);
  --black-overlay-700: rgba(0,0,0,0.720);
  --black-overlay-800: rgba(0,0,0,0.810);
  --black-overlay-900: rgba(0,0,0,0.900);
  --linear-black: linear-gradient(to bottom, rgba(46,46,46,0.180) 0%,rgba(36,36,36,0.140) 100%);
  --linear-blue: linear-gradient(to bottom, rgba(17,142,245,0.067) 0%,rgba(7,127,247,0.029) 100%);
  --angular-white: conic-gradient(rgba(255,255,255,1) 72.38%,rgba(255,255,255,1) 99.87%);
  --angular-black: conic-gradient(rgba(56,56,56,0.220) 72.38%,rgba(56,56,56,0.220) 99.87%);
  --angular-green: conic-gradient(rgba(23,117,75,0.092) 72.38%,rgba(23,117,75,0.092) 99.87%);
  --angular-red: conic-gradient(rgba(205,41,41,0.804) 72.38%,rgba(205,41,41,0.804) 99.87%);
  --angular-blue: conic-gradient(rgba(0,110,219,0.000) 72.38%,rgba(0,110,219,0.000) 99.87%);
}

:root {
  --border-radius-tiny: 4px;
  --border-radius-sm: 8px;
  --border-radius: 8px;
  --border-radius-md: 10px;
  --border-radius-lg: 12px;
  --border-radius-xl: 16px;
  --border-radius-2xl: 20px;
  --border-radius-full: 999px;
}

:root {
  --shadow-xs: rgba(0, 0, 0, 0.05) 0px 0.5px 0px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 2px 4px 0px;
  --shadow-sm: 0px 1px 2px rgba(0,0,0,0.100);
  --shadow-base: 0px 0px 1px rgba(0,0,0,0.450), 0px 1px 2px rgba(0,0,0,0.100);
  --shadow-md: 0px 0px 1px rgba(0,0,0,0.120), 0px 0.5px 2px rgba(0,0,0,0.150), 0px 2px 3px rgba(0,0,0,0.160);
  --shadow-lg: 0px 0px 1px rgba(0,0,0,0.350), 0px 6px 8px -4px rgba(0,0,0,0.100);
  --shadow-xl: 0px 0px 1px rgba(0,0,0,0.190), 0px 1px 2px rgba(0,0,0,0.070), 0px 6px 15px -5px rgba(0,0,0,0.110);
  --shadow-2xl: 0px 0px 1px rgba(0,0,0,0.200), 0px 1px 3px rgba(0,0,0,0.050), 0px 10px 24px -3px rgba(0,0,0,0.100);
  --focus-default: 0px 0px 0px 2px #c9c9c9;
  --focus-blue: 0px 0px 0px 2px #65b9fc;
  --focus-green: 0px 0px 0px 2px #5bb98c;
  --focus-yellow: 0px 0px 0px 2px #fff0ad;
  --focus-red: 0px 0px 0px 2px #eb9091;
  --custom-status: 0px 0px 0px 1.5px #ffffff;
  --custom-shadow-sm: 0px 1px 4px rgba(0,0,0,0.100);
  --drop-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0), 0px 2px 4px rgba(0, 0, 0, 0.05);
}

:root {
  --input-padding: 6px 8px;
  --dropdown-padding: 4px 8px;
  --grid-padding: 10px 8px;
  --disabled-input-padding: 3px 8px;
  --number-card-padding: 8px 8px 8px 12px;
}

:root {
  --font-stack: "Inter V", "Inter", "-apple-system", "BlinkMacSystemFont","Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell","Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --text-tiny: 11px;
  --text-2xs: 12px;
  --text-xs: 12px;
  --text-sm: 13px;
  --text-md: 13px;
  --text-base: 14px;
  --text-lg: 16px;
  --text-xl: 18px;
  --text-2xl: 20px;
  --text-3xl: 24px;
  --text-4xl: 26px;
  --text-5xl: 28px;
  --text-6xl: 32px;
  --text-7xl: 40px;
  --text-8xl: 44px;
  --text-9xl: 48px;
  --text-10xl: 52px;
  --text-11xl: 56px;
  --text-12xl: 64px;
  --weight-regular: 420;
  --weight-medium: 500;
  --weight-semibold: 600;
  --weight-bold: 700;
  --weight-black: 800;
  --text-line-height-3xl: 115%;
  --text-line-height-4xl: 160%;
  --text-line-height-7xl: 140%;
  --text-line-height-12xl: 130%;
  --text-line-height-14xl: 120%;
  --para-line-height-2-xs: 160%;
  --para-line-height-sm: 150%;
  --para-line-height-2xl: 148%;
  --para-line-height-3xl: 140%;
  --heading-color: var(--gray-900);
  --text-neutral: var(--gray-900);
  --text-color: var(--gray-800);
  --text-muted: var(--gray-700);
  --text-light: var(--gray-600);
  --text-dark: var(--fg-color);
}

:root,
[data-theme=light] {
  --brand-color: var(--primary);
  --padding-xs: 5px;
  --padding-sm: 6px;
  --padding-md: 15px;
  --padding-lg: 20px;
  --padding-xl: 30px;
  --padding-2xl: 40px;
  --margin-xs: 5px;
  --margin-sm: 10px;
  --margin-md: 15px;
  --margin-lg: 20px;
  --margin-xl: 30px;
  --margin-2xl: 40px;
  --modal-shadow: var(--shadow-md);
  --card-shadow: var(--shadow-sm);
  --btn-shadow: var(--shadow-xs);
  --icon-fill: transparent;
  --icon-fill-bg: var(--fg-color);
  --icon-stroke: var(--gray-800);
  --bg-blue: var(--blue-100);
  --bg-light-blue: var(--blue-50);
  --bg-dark-blue: var(--blue-300);
  --bg-green: var(--green-100);
  --bg-yellow: var(--yellow-100);
  --bg-orange: var(--orange-100);
  --bg-red: var(--red-100);
  --bg-gray: var(--gray-100);
  --bg-grey: var(--gray-100);
  --bg-light-gray: var(--gray-100);
  --bg-dark-gray: var(--gray-400);
  --bg-darkgrey: var(--gray-400);
  --bg-purple: var(--purple-100);
  --bg-pink: var(--pink-50);
  --bg-cyan: var(--cyan-50);
  --text-on-blue: var(--blue-700);
  --text-on-light-blue: var(--blue-600);
  --text-on-dark-blue: var(--blue-800);
  --text-on-green: var(--green-800);
  --text-on-yellow: var(--yellow-700);
  --text-on-orange: var(--orange-700);
  --text-on-red: var(--red-700);
  --text-on-gray: var(--gray-700);
  --text-on-grey: var(--gray-700);
  --text-on-darkgrey: var(--gray-800);
  --text-on-dark-gray: var(--gray-800);
  --text-on-light-gray: var(--gray-800);
  --text-on-purple: var(--purple-700);
  --text-on-pink: var(--pink-700);
  --text-on-cyan: var(--cyan-700);
  --alert-text-danger: var(--red-600);
  --alert-text-warning: var(--yellow-700);
  --alert-text-info: var(--blue-700);
  --alert-text-success: var(--green-700);
  --alert-bg-danger: var(--red-50);
  --alert-bg-warning: var(--yellow-50);
  --alert-bg-info: var(--blue-50);
  --alert-bg-success: var(--green-100);
  --bg-color: white;
  --fg-color: white;
  --subtle-accent: var(--gray-50);
  --subtle-fg: var(--gray-100);
  --navbar-bg: white;
  --fg-hover-color: var(--gray-100);
  --card-bg: var(--fg-color);
  --disabled-text-color: var(--gray-600);
  --disabled-control-bg: var(--gray-50);
  --control-bg: var(--gray-100);
  --control-bg-on-gray: var(--gray-200);
  --awesomebar-focus-bg: var(--fg-color);
  --modal-bg: white;
  --toast-bg: var(--modal-bg);
  --popover-bg: white;
  --awesomplete-hover-bg: var(--control-bg);
  --btn-primary: var(--gray-900);
  --btn-default-bg: var(--gray-100);
  --btn-default-hover-bg: var(--gray-300);
  --sidebar-select-color: var(--gray-200);
  --scrollbar-thumb-color: var(--gray-400);
  --scrollbar-track-color: var(--gray-200);
  --shadow-inset: inset 0px -1px 0px var(--gray-300);
  --border-color: var(--gray-200);
  --dark-border-color: var(--gray-300);
  --table-border-color: var(--gray-200);
  --highlight-color: var(--gray-50);
  --yellow-highlight-color: var(--yellow-50);
  --btn-group-border-color: var(--gray-300);
  --placeholder-color: var(--gray-50);
  --highlight-shadow: 1px 1px 10px var(--blue-50), 0px 0px 4px var(--blue-600);
  --code-block-bg: var(--gray-900);
  --code-block-text: var(--gray-400);
  --primary-color: var(--gray-900);
  --btn-height: 28px;
  --input-height: 28px;
  --input-disabled-bg: var(--gray-200);
  --checkbox-right-margin: var(--margin-xs);
  --checkbox-size: 14px;
  --checkbox-color: var(--neutral-black);
  --checkbox-focus-shadow: 0 0 0 2px var(--gray-300);
  --checkbox-gradient: linear-gradient(180deg, var(--primary) -124.51%, var(--primary) 100%);
  --checkbox-disabled-gradient: linear-gradient(180deg, var(--disabled-control-bg) -124.51%, var(--disabled-control-bg) 100%);
  --switch-bg: var(--gray-300);
  --diff-added: var(--green-100);
  --diff-removed: var(--red-100);
  --right-arrow-svg: url("data: image/svg+xml;utf8, <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.25 7.5L4.75 4L1.25 0.5' stroke='%231F272E' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  --left-arrow-svg: url("data: image/svg+xml;utf8, <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.5 9.5L4 6l3.5-3.5' stroke='%231F272E' stroke-linecap='round' stroke-linejoin='round'></path></svg>");
}

.no-border {
  border: none !important;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.app-change-log-body h1 {
  font-size: var(--text-2xl);
}

.new-version-log .new-version-links {
  padding: var(--padding-xs) 0px;
}
.new-version-log:not(:last-child) {
  margin-bottom: 1em;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.hide {
  display: none !important;
}

.btn-link {
  box-shadow: none !important;
  outline: none;
}
.btn-link .icon, .btn-link .es-icon, .btn-link:hover {
  text-decoration: none !important;
}

.hidden {
  display: none !important;
}

.margin {
  margin: var(--margin-sm);
}

.margin-top {
  margin-top: var(--margin-sm);
}

.margin-bottom {
  margin-bottom: var(--margin-sm);
}

.margin-left {
  margin-left: var(--margin-sm);
}

.margin-right {
  margin-right: var(--margin-sm);
}

.icon, .es-icon {
  display: inline-block;
  font-size: 0;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  fill: var(--icon-fill);
  stroke: var(--icon-stroke);
}

.es-icon {
  fill: var(--icon-stroke);
  stroke: var(--icon-fill);
}

/* like icon */
use.like-icon {
  --icon-stroke: transparent;
  cursor: pointer;
  stroke: var(--gray-800);
}

#icon-file-large {
  stroke: none;
}

#icon-folder-normal-large {
  stroke: none;
}

#icon-close {
  fill: var(--invert-neutral);
}

.icon > .close-alt, .es-icon > .close-alt {
  fill: var(--gray-500);
}

.liked use.like-icon {
  --icon-stroke: var(--red-500);
  fill: var(--icon-stroke);
}

.icon-xs {
  width: 12px;
  height: 12px;
}

.icon-sm {
  width: 16px;
  height: 16px;
}

.icon-base,
.icon-md {
  width: 20px;
  height: 20px;
}

.icon-lg {
  width: 24px;
  height: 24px;
}

.icon-xl {
  width: 75px;
  height: 75px;
}

.no-stroke {
  stroke: none;
}

.alert.alert-info {
  color: var(--alert-text-info);
  background-color: var(--alert-bg-info);
  border: none;
}
.alert.alert-success {
  color: var(--alert-text-success);
  background-color: var(--alert-bg-success);
  border: none;
}
.alert.alert-warning {
  color: var(--alert-text-warning);
  background-color: var(--alert-bg-warning);
  border: none;
}
.alert.alert-danger {
  color: var(--alert-text-danger);
  background-color: var(--alert-bg-danger);
  border: none;
}

#alert-container {
  position: fixed;
  bottom: 0px;
  right: 20px;
  z-index: 1050;
}
@media (max-width: 767.98px) {
  #alert-container {
    right: 0;
  }
}

.desk-alert {
  box-shadow: var(--modal-shadow);
  width: 400px;
  min-height: 50px;
  max-height: 200px;
  background-color: var(--toast-bg);
  animation-name: backInRight;
  animation-duration: 600ms;
  overflow-y: auto;
  position: relative;
  padding: 0px;
  border-radius: var(--border-radius-md);
}
.desk-alert.red {
  --toast-bg: var(--alert-bg-danger);
}
.desk-alert.yellow {
  --toast-bg: var(--alert-bg-warning);
}
.desk-alert.orange {
  --toast-bg: var(--alert-bg-warning);
}
.desk-alert.blue {
  --toast-bg: var(--alert-bg-info);
}
.desk-alert.green {
  --toast-bg: var(--alert-bg-success);
}
.desk-alert .alert-message-container {
  padding: var(--padding-md);
  padding-right: var(--padding-2xl);
}
.desk-alert .alert-message-container .icon, .desk-alert .alert-message-container .es-icon {
  margin-right: var(--margin-sm);
}
.desk-alert .alert-message-container .alert-title-container {
  display: flex;
  align-items: center;
}
.desk-alert .alert-message-container .alert-message {
  font-weight: 500;
  color: var(--text-color);
  line-height: 20px;
}
.desk-alert .alert-message-container .alert-subtitle {
  font-size: var(--text-md);
  padding-left: 34px;
  color: var(--text-light);
}
.desk-alert .close {
  position: absolute;
  top: 18px;
  right: var(--padding-md);
  color: inherit;
  opacity: 1;
  font-size: inherit;
}
.desk-alert .close .icon, .desk-alert .close .es-icon {
  fill: var(--text-muted);
}
.desk-alert .next-action-container {
  display: flex;
}
.desk-alert .next-action-container .next-action {
  border: none;
  background: none;
  width: 100%;
  border-top: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  padding: var(--padding-sm);
  outline: none;
  font-size: var(--text-sm);
  font-weight: var(--weight-medium);
  letter-spacing: 0.02em;
  color: var(--text-light);
}
.desk-alert .next-action-container .next-action:hover {
  color: var(--text-color);
}
.desk-alert .next-action-container .next-action:last-child {
  border-right: none;
}
.desk-alert.out {
  animation-name: backOutRight;
  animation-duration: 1.6s;
}
@media (max-width: 767.98px) {
  .desk-alert {
    width: 100vw;
  }
}

@keyframes backInRight {
  0% {
    transform: translateX(2000px) scale(0.8);
    opacity: 0.7;
  }
  80% {
    transform: translateX(0px) scale(0.8);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes backOutRight {
  0% {
    opacity: 1;
  }
  100% {
    transform: translateX(2000px);
    opacity: 0.7;
  }
}
.center-content, .btn.icon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  border: none;
  border-radius: var(--border-radius);
  box-shadow: none;
  font-size: var(--text-base);
  font-weight: var(--weight-regular);
  letter-spacing: 0.02em;
}
.btn:not(.btn-md):not(.btn-lg) {
  padding: 4px 8px;
}
.btn:active {
  color: var(--text-color) !important;
  background-color: var(--control-bg) !important;
  box-shadow: var(--focus-default) !important;
}
.btn.icon-btn {
  height: var(--btn-height);
  padding: 0px;
}
.btn.icon-btn.btn-default, .btn.icon-btn.btn-secondary {
  min-width: 28px;
}

.btn-secondary-dark {
  color: #fff;
  background-color: #7c7c7c;
  border-color: #7c7c7c;
  color: #fff;
}
.btn-secondary-dark:hover {
  color: #fff;
  background-color: #7f7f7f;
  border-color: #7c7c7c;
}
.btn-secondary-dark:focus, .btn-secondary-dark.focus {
  color: #fff;
  background-color: #7f7f7f;
  border-color: #7c7c7c;
  box-shadow: 0 0 0 0.2rem rgba(144, 144, 144, 0.5);
}
.btn-secondary-dark.disabled, .btn-secondary-dark:disabled {
  color: #fff;
  background-color: #7c7c7c;
  border-color: #7c7c7c;
}
.btn-secondary-dark:not(:disabled):not(.disabled):active, .btn-secondary-dark:not(:disabled):not(.disabled).active, .show > .btn-secondary-dark.dropdown-toggle {
  color: #fff;
  background-color: #7f7f7f;
  border-color: #5c5c5c;
}
.btn-secondary-dark:not(:disabled):not(.disabled):active:focus, .btn-secondary-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(144, 144, 144, 0.5);
}
.btn-secondary-dark:hover, .btn-secondary-dark:active, .btn-secondary-dark:focus {
  color: #fff;
}
.btn-secondary-dark .icon, .btn-secondary-dark .es-icon {
  --icon-stroke: currentColor;
}

.btn-primary-light {
  color: #171717;
  background-color: #e2e2e2;
  border-color: #383838;
  color: var(--primary);
}
.btn-primary-light:hover {
  color: #171717;
  background-color: #ededed;
  border-color: #e2e2e2;
}
.btn-primary-light:focus, .btn-primary-light.focus {
  color: #171717;
  background-color: #ededed;
  border-color: #e2e2e2;
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}
.btn-primary-light.disabled, .btn-primary-light:disabled {
  color: #171717;
  background-color: #e2e2e2;
  border-color: #383838;
}
.btn-primary-light:not(:disabled):not(.disabled):active, .btn-primary-light:not(:disabled):not(.disabled).active, .show > .btn-primary-light.dropdown-toggle {
  color: #171717;
  background-color: #e2e2e2;
  border-color: #ededed;
}
.btn-primary-light:not(:disabled):not(.disabled):active:focus, .btn-primary-light:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}
.btn-primary-light:hover, .btn-primary-light:active {
  color: var(--primary);
}
.btn-primary-light:focus {
  box-shadow: var(--focus-default);
}

.btn.btn-secondary {
  background-color: var(--control-bg);
  color: var(--text-color);
}
.btn.btn-secondary:hover, .btn.btn-secondary:active {
  background-color: var(--btn-default-hover-bg);
  color: var(--text-color);
}

.btn.btn-default {
  background-color: var(--control-bg);
  color: var(--text-color);
}
.btn.btn-default:hover, .btn.btn-default:active {
  background: var(--btn-default-hover-bg);
  color: var(--text-color);
}

.btn.btn-primary {
  background-color: var(--btn-primary);
  color: var(--neutral);
  white-space: nowrap;
  --icon-stroke: currentColor;
  --icon-fill-bg: var(--btn-primary);
}
.btn.btn-primary:active {
  color: var(--gray-100) !important;
  background-color: var(--invert-neutral) !important;
}

.btn.btn-danger {
  background-color: var(--danger);
  color: #fff;
}

.btn-reset {
  padding: 0;
  margin: 0;
  border: 0;
  font-size: inherit;
  background-color: inherit;
}

[data-theme=dark] .btn-primary-light {
  background-color: var(--bg-dark-gray);
  box-shadow: none;
}
[data-theme=dark] .btn-primary:active {
  color: var(--gray-900) !important;
  background-color: var(--invert-neutral) !important;
}

h5.modal-title {
  margin: 0px !important;
}

body.modal-open[style^=padding-right] {
  padding-right: 12px !important;
}
body.modal-open[style^=padding-right] header.navbar {
  padding-right: 12px !important;
  margin-right: -12px !important;
}

.modal {
  scrollbar-width: auto;
}
.modal::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
@media (max-width: 991px) {
  .modal {
    scrollbar-width: none;
  }
  .modal::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.modal .modal-content {
  border-color: var(--border-color);
  overflow: hidden;
}
.modal .modal-header {
  position: sticky;
  top: 0;
  z-index: 3;
  background: inherit;
  padding: var(--padding-md) var(--padding-lg);
  border-bottom: 1px solid var(--border-color);
}
.modal .modal-header .modal-title {
  font-weight: 500;
  line-height: 2em;
  font-size: 1.25rem;
  max-width: calc(100% - 80px);
}
.modal .modal-header .modal-actions {
  position: absolute;
  top: 10px;
  right: 5px;
}
.modal .modal-header .modal-actions .btn-modal-minimize {
  padding-right: 0;
}
.modal .modal-header .modal-actions .btn-modal-minimize .icon, .modal .modal-header .modal-actions .btn-modal-minimize .es-icon {
  width: 14px;
  height: 14px;
}
.modal .modal-header .modal-actions .btn-modal-minimize .icon use, .modal .modal-header .modal-actions .btn-modal-minimize .es-icon use {
  stroke: var(--gray-500);
}
.modal .modal-body {
  padding: var(--padding-md) var(--padding-lg);
}
.modal .modal-body .form-layout:first-child > .form-page .visible-section:first-child {
  padding-top: 0;
}
.modal .modal-body .form-layout:first-child > .form-page .visible-section:first-child .section-body {
  margin-top: 0;
}
.modal .modal-body .form-layout:last-child > .form-page .visible-section:last-child {
  padding-bottom: 0;
}
.modal .awesomplete ul {
  z-index: 2;
}
.modal .modal-footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background: inherit;
  padding: var(--padding-md) var(--padding-lg);
  border-top: 1px solid var(--border-color);
  justify-content: space-between;
}
.modal .modal-footer button {
  box-shadow: none;
}
.modal .modal-footer .standard-actions button:not(:last-child) {
  margin-right: var(--margin-xs);
}
.modal .modal-footer > * {
  margin: 0;
}
.modal .form-section {
  padding: var(--padding-sm) 0;
}
.modal .form-section:not(:first-child) {
  border-top: 1px solid var(--border-color);
}
.modal .form-section.hide-border {
  border-top: none !important;
  padding-top: 0px !important;
}
.modal .form-section .form-column:first-child {
  padding-left: 0;
}
.modal .form-section .form-column:last-child {
  padding-right: 0;
}
.modal .form-section .section-head {
  padding: 0;
  font-size: var(--text-md);
}
.modal .form-section .section-head:not(.collapsed) {
  padding-bottom: var(--padding-sm);
}
.modal .hasDatepicker {
  z-index: 1140;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.modal-minimize {
  position: initial;
  height: 0;
  width: 0;
}
.modal-minimize .modal-dialog {
  position: fixed;
  right: 15px;
  bottom: 0;
  margin: 0;
  min-width: 400px;
}
.modal-minimize .modal-dialog .modal-header {
  padding-top: var(--padding-sm);
  padding-bottom: var(--padding-xs);
}
.modal-minimize .modal-dialog .modal-content {
  min-height: 0;
  border-radius: var(--border-radius-md);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: var(--shadow-lg);
}
@media (max-width: 767.98px) {
  .modal-minimize .modal-dialog {
    right: 0;
    width: 100%;
    min-width: 100%;
  }
}
.modal-minimize .modal-body, .modal-minimize .modal-footer {
  display: none;
}

/* msgprint dialog */
.msgprint-dialog .modal-content {
  min-height: 110px;
}

.msgprint {
  word-wrap: break-word;
}
.msgprint pre {
  text-align: left;
}
.msgprint a:not(.btn) {
  text-decoration: underline;
}

.modal .to_section form {
  display: flex;
  align-items: center;
}
.modal .to_section form .frappe-control:first-child {
  flex: 1;
}
.modal .to_section form .frappe-control:first-child[data-fieldname=sender] {
  margin-right: 10px;
}
.modal .to_section form .frappe-control:last-child {
  margin-left: 10px;
  margin-bottom: -24px;
}
.modal .to_section form .frappe-control:last-child button {
  height: calc(1.5em + 0.7rem);
}

.modal [data-fieldname=email_template_section_break] form {
  display: flex;
  align-items: center;
}
.modal [data-fieldname=email_template_section_break] form .frappe-control:first-child {
  flex: 1;
}
.modal [data-fieldname=email_template_section_break] form .frappe-control:first-child[data-fieldname=email_template] {
  margin-right: 10px;
}
.modal [data-fieldname=email_template_section_break] form .frappe-control:last-child {
  margin-bottom: -8px;
}

.modal .hidden-xs {
  display: none !important;
}

.dialog-assignment-row {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: var(--border-radius-md);
  color: var(--text-color);
}
.dialog-assignment-row:not(:last-child) {
  margin-bottom: 5px;
}
.dialog-assignment-row .btn-group {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.dialog-assignment-row .btn-group button {
  display: inline-flex;
  align-items: center;
}
.dialog-assignment-row .assignee {
  flex: 1;
}
.dialog-assignment-row:hover .btn-group {
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}
.dialog-assignment-row .avatar {
  margin-right: var(--margin-md);
}